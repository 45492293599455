import { Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

function AuthSocialLogin() {
  // use i18n
  const { t } = useTranslation(['auth']);

  return (
    <Box>
      <Box mb={3}>
        <Button
          as="a"
          colorScheme="facebook"
          width="100%"
          href={`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/facebook`}
        >
          <HStack spacing={1}>
            <Center>
              <Image
                src="/image-icon/login-fb.png"
                alt="facebook"
                width={22}
                height={22}
                quality={100}
              ></Image>
            </Center>
            <Text>
              {t('auth.form.social.loginWithFacebook', {
                // TH: เข้าสู่ระบบด้วย Facebook
                // EN: Login with Facebook
              })}
            </Text>
          </HStack>
        </Button>
      </Box>
      <Box>
        <Button
          as="a"
          variant="outline"
          colorScheme="gray"
          border="1px solid"
          borderColor="gray.200"
          width="100%"
          _hover={{
            bg: 'gray.50',
          }}
          href={`${process.env.NEXT_PUBLIC_API_URL}/v1/auth/google`}
        >
          <HStack spacing={1}>
            <Center>
              <Image
                src="/image-icon/login-google.png"
                alt="google"
                width={24}
                height={24}
                quality={100}
              ></Image>
            </Center>
            <Text>
              {t('auth.form.social.loginWithGoogle', {
                // TH: เข้าสู่ระบบด้วย Google
                // EN: Login with Google
              })}
            </Text>
          </HStack>
        </Button>
      </Box>
    </Box>
  );
}

export default AuthSocialLogin;
