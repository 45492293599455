import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';
import NextLink from 'next/link';

export interface IAppBreadcrumbItem {
  label: string;
  href?: string;
}

type AppBreadcrumbProps = {
  breadcrumb: IAppBreadcrumbItem[];
};

function AppBreadcrumb({ breadcrumb }: AppBreadcrumbProps) {
  // use router
  const { pathname } = useRouter();

  return (
    <Box>
      <Breadcrumb separator=">" color="gray.600" className="app-breadcrumb">
        {breadcrumb.map((item, index) => (
          <BreadcrumbItem key={index} isCurrentPage={pathname === item.href}>
            {item.href ? (
              <NextLink href={item.href} passHref legacyBehavior>
                <BreadcrumbLink>{item.label}</BreadcrumbLink>
              </NextLink>
            ) : (
              <Text noOfLines={1}>{item.label}</Text>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  );
}

export default AppBreadcrumb;
