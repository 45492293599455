import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

type PageTitleMobileProps = {
  backLink?: string;
  children?: React.ReactNode;
};

function PageTitleMobile({ children, backLink }: PageTitleMobileProps) {
  // use router
  const router = useRouter();

  return (
    <Box
      bg="#F36621"
      height="32px"
      display={{
        lg: 'none',
      }}
    >
      <Container h="100%">
        <HStack color="white" h="100%">
          {backLink && (
            <Button
              variant="unstyled"
              minW="auto"
              minHeight="auto"
              onClick={() => {
                router.push(backLink, backLink, {
                  locale: router.locale,
                });
              }}
            >
              <Image
                src="/image-icon/arrow-prev-white.png"
                width={9}
                height={12}
                alt="next"
              ></Image>
            </Button>
          )}
          <Heading fontSize="17px" fontWeight="400" color="white">
            {children}
          </Heading>
        </HStack>
      </Container>
    </Box>
  );
}

export default PageTitleMobile;
