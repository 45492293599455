import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

type InputCheckboxGroupProp = {
  name: string;
  label?: string | React.ReactNode;
  description?: string;
  control: Control<any>;
  rules: any;
  watch?: UseFormWatch<any>;
};

function InputCheckboxGroup({
  name,
  label,
  placeholder,
  description,
  control,
  rules,
  watch,
  ...rest
}: InputCheckboxGroupProp & CheckboxProps) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <FormControl isInvalid={name in errors}>
          <Checkbox
            {...rest}
            value={value ?? ''}
            isChecked={value}
            onChange={(val) => onChange(val)}
          >
            {label}
          </Checkbox>
          {description && <FormHelperText>{description}</FormHelperText>}
          {errors[name]?.message && (
            <FormErrorMessage mt={1}>
              {errors[name]?.message as string}
            </FormErrorMessage>
          )}
        </FormControl>
      )}
      name={name}
      rules={rules}
    />
  );
}

export default InputCheckboxGroup;
