import { Box, Button, Center, HStack, Link, Stack } from '@chakra-ui/react';
import InputCheckboxGroup from 'components/inputs/InputCheckboxGroup';
import InputTextGroup from 'components/inputs/InputTextGroup';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useForm } from 'react-hook-form';
import AuthSocialLogin from './AuthSocialLogin';

type AuthLoginFormProps = {
  onSubmit: (data: any) => void;
  loading?: boolean;
};

function AuthLoginForm({ onSubmit, loading = false }: AuthLoginFormProps) {
  // use i18n
  const { t } = useTranslation(['auth', 'common']);

  // use react-hook-form
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box>
            <InputTextGroup
              name="email"
              type="email"
              label={t('form.label.email', {
                ns: 'common',
              })}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('form.message.required', { ns: 'common' }),
                },
              }}
            ></InputTextGroup>
          </Box>
          <Box>
            <InputTextGroup
              name="password"
              type="password"
              label={t('form.label.password', {
                ns: 'common',
              })}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('form.message.required', { ns: 'common' }),
                },
              }}
            ></InputTextGroup>
          </Box>
          <HStack justifyContent="space-between" mt={3}>
            <Box>
              <InputCheckboxGroup
                name="remember"
                label={t('auth.form.label.remember', {
                  // TH: จำไว้ในระบบ
                  // EN: Remember me
                })}
                control={control}
                rules={{
                  required: false,
                }}
              ></InputCheckboxGroup>
            </Box>
            <Box>
              <NextLink href="/forgot-password" passHref legacyBehavior>
                <Link>
                  {t('auth.form.label.forgotPassword', {
                    // TH: ลืมรหัสผ่าน?
                    // EN: Forgot password?
                  })}
                </Link>
              </NextLink>
            </Box>
          </HStack>
          <Box>
            <Button
              type="submit"
              bg="gray.800"
              width="100%"
              isLoading={loading}
            >
              {t('auth.login.form.button.login', {
                // TH: เข้าสู่ระบบ
                // EN: Login
              })}
            </Button>
          </Box>
          <Center py={1}>
            {t('auth.form.social.divider', {
              // TH: หรือ
              // EN: or
            })}
          </Center>
          <AuthSocialLogin></AuthSocialLogin>
        </Stack>
      </form>
    </Box>
  );
}

export default AuthLoginForm;
