import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  Grid,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import AuthLoginForm from 'components/auth/AuthLoginForm';
import PageHeader from 'components/page-header/PageHeader';
import PageTitleMobile from 'components/page-header/PageTitleMobile';
import { IAuthLoginDto } from 'dtos/auth.dto';
import useCart from 'hooks/useCart';
import useCatchError from 'hooks/useCatchError';
import useUser from 'hooks/useUser';
import { ICart } from 'interfaces/cart.interface';
import { IUser } from 'interfaces/user.interface';
import { withIronSessionSsr } from 'iron-session/next';
import RootLayout from 'layouts/RootLayout';
import _axios from 'libs/axios';
import { sessionOptions } from 'libs/iron-session';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { CartRepository } from 'respository/cart.repository';
import { NextPageWithLayout } from './_app';

const LoginPage: NextPageWithLayout = () => {
  // use router
  const router = useRouter();

  // use user
  const { mutateUser, updateUser } = useUser();

  const { t } = useTranslation(['auth']);

  // use cart
  const { cartUpdate } = useCart();

  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [isDesktop] = useMediaQuery('(min-width: 1024px)');

  // use catchError
  const catchError = useCatchError();

  // init loading state
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: IAuthLoginDto) => {
    try {
      console.log('values', values);

      setLoading(true);
      const { data } = await axios.post<IUser>(
        '/api/users/login-session',
        values
      );

      if (data.accessToken && data.id > 0) {
        // set auth token to axios
        _axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.accessToken}`;

        /**
         * Add items to cart from local storage to cart in api
         * when cart items from api is empty
         */

        // get cart by user from api
        /* const cart = await CartRepository.findOne();

        // get cart from local storage
        const _cart = localStorage.getItem('cart');
        const _cartParsed: ICart = _cart ? JSON.parse(_cart) : null; */

        // if cart is not empty
        /* if (cart && cart.items.length === 0) {
          // if cart from local storage is not empty
          if (_cartParsed && _cartParsed.items.length > 0) {
            // add items to cart
            await CartRepository.addItems(
              _cartParsed.items.map((item) => {
                return {
                  productId: item.productId,
                  quantity: item.quantity,
                  attributeOptionIds:
                    item.attributeOptions && item.attributeOptions.length > 0
                      ? item.attributeOptions.map(
                          (attributeOption) => attributeOption.id
                        )
                      : undefined,
                };
              })
            );

            // get cart by user from api
            const cart = await CartRepository.findOne();

            cartUpdate(cart);
          }
        } */

        // update user by mutate SWR
        mutateUser(data, false);

        updateUser(data);

        // redirect to home page
        router.push('/');
      }
    } catch (error) {
      catchError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Head>
        <title>
          {t('auth.login.title', {
            // TH: เข้าสู่ระบบ
            // EN: Login
          })}
        </title>
      </Head>
      <Box>
        <PageHeader title={t('auth.login.title')}></PageHeader>
        <PageTitleMobile>
          {t('auth.login.welcomeTitle', {
            // TH: ยินดีต้อนรับ
            // EN: Welcome
          })}
        </PageTitleMobile>
        <Container>
          <Grid
            pt={{
              base: 4,
              md: 8,
              lg: 12,
            }}
            pb={{
              base: 12,
              lg: 14,
            }}
            alignItems="flex-start"
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: '45% 1fr',
            }}
            columnGap={{
              base: 0,
              md: 8,
            }}
          >
            <Box>
              {isDesktop && (
                <Heading as="h1" mb={2} fontSize="2xl" fontWeight={400}>
                  {t('auth.login.title')}
                </Heading>
              )}

              {router.query.socialLoginError && (
                <Alert status="error" mb={4} mt={4}>
                  <AlertIcon></AlertIcon>
                  <AlertDescription>
                    {t('auth.login.social.alert.error', {
                      // TH: ไม่สามารถเข้าสู่ระบบด้วยบัญชีที่เชื่อมต่อไว้ได้
                      // EN: Unable to login with connected account
                    })}
                  </AlertDescription>
                </Alert>
              )}

              <AuthLoginForm
                onSubmit={handleSubmit}
                loading={loading}
              ></AuthLoginForm>
              {isMobile && <Box height="1px" my={6} bg="gray.100"></Box>}
            </Box>
            <Box height="100%">
              <Center
                height="100%"
                bg="#EADECE"
                p={{
                  base: 7,
                  lg: 0,
                }}
              >
                <Box textAlign="center">
                  <Heading as="h2" mb={3} fontWeight="300">
                    {t('auth.register.callAction.title', {
                      // TH: สมัครสมาชิกใหม่
                      // EN: New member
                    })}
                  </Heading>
                  <NextLink href="/register" passHref legacyBehavior>
                    <Button as="a" bg="gray.800" minWidth="150px">
                      {t('auth.register.callAction.button', {
                        // TH: ลงทะเบียน
                        // EN: Register
                      })}
                    </Button>
                  </NextLink>
                </Box>
              </Center>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

LoginPage.getLayout = (page) => {
  return <RootLayout>{page}</RootLayout>;
};

export const getServerSideProps = withIronSessionSsr(async function ({
  req,
  res,
  locale,
}) {
  const user = req.session.user;

  const _locale = locale || 'th';

  // log user
  console.log('user', user);

  if (user === undefined || !user?.accessToken) {
    return {
      props: {
        ...(await serverSideTranslations(_locale, ['common', 'auth'])),
      },
    };
  } else {
    // if user is not undefined
    // redirect to home page
    res.writeHead(302, {
      Location: '/',
    });
    res.end();
  }

  return {
    props: {
      user: req.session.user,
      ...(await serverSideTranslations(_locale, ['common', 'auth'])),
    },
  };
},
sessionOptions);

export default LoginPage;
