import { Box, Container, Heading, useMediaQuery } from '@chakra-ui/react';
import AppBreadcrumb, {
  IAppBreadcrumbItem,
} from 'components/breadcrumb/AppBreadcrumb';
import React from 'react';

type PageHeaderProps = {
  title?: string;
  breadcrumb?: IAppBreadcrumbItem[];
  backLink?: string;
  containerMaxWidth?: string;
};

function PageHeader({
  title,
  breadcrumb,
  backLink,
  containerMaxWidth = '850px',
}: PageHeaderProps) {
  return (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="primary.400"
        pt={8}
        pb={4}
        bgGradient="linear(to-t, white 55%, #ffd5b1 100%)"
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <Container maxWidth={containerMaxWidth}>
          {title && (
            <Heading color="gray.600" fontWeight="300" fontSize="lg">
              {title}
            </Heading>
          )}
          {breadcrumb && breadcrumb.length > 0 && (
            <Box fontSize="lg">
              <AppBreadcrumb breadcrumb={breadcrumb}></AppBreadcrumb>
            </Box>
          )}
        </Container>
      </Box>
      <Box
        py={2}
        display={{
          base: 'block',
          lg: 'none',
        }}
      >
        <Container>
          {title && (
            <Heading color="gray.600" fontWeight="300" fontSize="md">
              {title}
            </Heading>
          )}
          {breadcrumb && breadcrumb.length > 0 && (
            <AppBreadcrumb breadcrumb={breadcrumb}></AppBreadcrumb>
          )}
        </Container>
      </Box>
    </>
  );
}

export default PageHeader;
