import {
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { get } from 'lodash';
import React from 'react';
import { Control, Controller, FieldError, UseFormWatch } from 'react-hook-form';

type InputTextGroupProp = {
  name: string;
  type?: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  description?: string;
  control: Control<any>;
  rules: any;
  watch?: UseFormWatch<any>;
};

function InputTextGroup({
  name,
  label,
  type,
  placeholder,
  description,
  control,
  rules,
  watch,
  ...rest
}: InputTextGroupProp & InputProps) {
  const [passwordShown, setPasswordShown] = React.useState(false);

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <FormControl
          isInvalid={
            (get(errors, name) as FieldError)?.message !== '' &&
            (get(errors, name) as FieldError)?.message !== undefined
          }
        >
          {label && (
            <FormLabel mb={1}>
              {label}
              {/* {get(rules, 'required')?.value ? '*' : ''} */}
            </FormLabel>
          )}
          <InputGroup>
            <Input
              {...rest}
              type={
                type
                  ? type === 'password'
                    ? passwordShown
                      ? 'text'
                      : 'password'
                    : type
                  : 'text'
              }
              name={name}
              placeholder={placeholder}
              onChange={(val) => onChange(val)}
              value={value ?? ''}
            />
            {type === 'password' && (
              <InputRightElement>
                <Button
                  size="sm"
                  onClick={() => {
                    setPasswordShown(!passwordShown);
                  }}
                  variant="unstyled"
                  paddingLeft={2}
                  paddingRight={4}
                  minWidth="auto"
                >
                  {passwordShown ? (
                    <EyeInvisibleOutlined></EyeInvisibleOutlined>
                  ) : (
                    <EyeOutlined></EyeOutlined>
                  )}
                </Button>
              </InputRightElement>
            )}
          </InputGroup>
          {description && <FormHelperText>{description}</FormHelperText>}
          {(get(errors, name) as FieldError)?.message && (
            <FormErrorMessage mt={1}>
              {(get(errors, name) as FieldError)?.message as string}
            </FormErrorMessage>
          )}
        </FormControl>
      )}
      name={name}
      rules={rules}
    />
  );
}

export default InputTextGroup;
